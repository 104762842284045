import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import '../styles/App.css'; // Import CSS khusus dari src/styles
import '../config/main'; 
const AddProduct = () => {
  const [accType, setType] = useState("");
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [harga, setHarga] = useState("");
  const [stokTerjual, setStokTerjual] = useState(0); // Perbaiki typo dari setSetok menjadi setStokTerjual
  const [name, setName] = useState("");
  const [akun, setAkun] = useState("");
  const navigate = useNavigate();

  const saveProduct = async (e) => {
    e.preventDefault();

    try {
      const data = {
        jenisAkun : accType,
        title,
        desc,
        harga: parseInt(harga), // Pastikan harga dikonversi ke integer jika diperlukan
        stokTerjual,
        name: name.toLowerCase(),
        akun,
      }
      await global.Config("POST",null,data)
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="columns mt-5 is-centered">
      <div className="column is-half">
        <form onSubmit={saveProduct}>
        <div className="field">
            <label className="label">Jenis Akun</label>
            <div className="control">
              <input
                type="text"
                className="input input-custom"
                value={accType}
                onChange={(e) => setType(e.target.value)}
                placeholder="Jenis Akun"
                required
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Title</label>
            <div className="control">
              <input
                type="text"
                className="input input-custom"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Title"
                required
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Description</label>
            <div className="control">
              <input
                type="text"
                className="input input-custom"
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
                placeholder="Description"
                required
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Price</label>
            <div className="control">
              <input
                type="text"
                className="input input-custom"
                value={harga}
                onChange={(e) => setHarga(e.target.value)}
                placeholder="Price"
                required
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Stok Terjual</label>
            <div className="control">
              <input
                type="text"
                className="input input-custom"
                value={stokTerjual}
                onChange={(e) => setStokTerjual(e.target.value)}
                placeholder="Stok Terjual"
                required
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Name</label>
            <div className="control">
              <input
                type="text"
                className="input input-custom"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
                required
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Akun</label>
            <div className="control">
              <textarea
                className="textarea textarea-custom"
                value={akun}
                onChange={(e) => setAkun(e.target.value)}
                placeholder="Akun"
                required
              />
            </div>
          </div>
          <div className="field">
            <button type="submit" className="button is-success is-fullwidth">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddProduct;

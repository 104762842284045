import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProductList from "./components/ProductList";
import AddProduct from "./components/AddProduct";
import EditProduct from "./components/EditProduct";
import { Helmet } from 'react-helmet';
import './styles/App.css';  // Import CSS ke App.js
function App() {
  return (
    <BrowserRouter>
      <Helmet>
        <title>Add Data - Lanzz Store</title>
      </Helmet>
      <Routes>
        <Route path="/" element={<ProductList />} />
        <Route path="/add" element={<AddProduct />} />
        <Route path="/edit/:id" element={<EditProduct />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

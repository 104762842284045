import axios from "axios";

global.HOST = "dtb-lanzz.lanzzstore.com";
global.PROTOCOL = "https"
global.PORT = 443;
global.HEADERS =  {
    Authorization: 'Bearer lanzzStore$1' // Sesuaikan dengan secret key Anda
  };

global.ALLHOST = `${global.PROTOCOL}://${global.HOST}${global.PORT !== 443 ? `:${global.PORT}` : ''}/products`
global.Config = async (method,id,data) => {
  const func = {
    GET: async () => await axios.get(`${global.ALLHOST}${id ? `/${id}` : ''}`, { headers: global.HEADERS }),
    POST: async () => await axios.post(`${global.ALLHOST}`, data, { headers: global.HEADERS }),
    PUT: async () => await axios.put(`${global.ALLHOST}/${id}`, data, { headers: global.HEADERS }),
    DELETE: async () => await axios.delete(`${global.ALLHOST}/${id}`, { headers: global.HEADERS }),
    PATCH: async () => await axios.patch(`${global.ALLHOST}/${id}`,data, { headers: global.HEADERS }),
  }
  return func[method ? method.toUpperCase() : "GET"]();
  }
  
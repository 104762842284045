import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import 'chart.js/auto';

const ProductList = () => {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    fetchProducts();
  }, []);
  
  
// test aja
  const fetchProducts = async () => {
    try {
      const { data } = await global.Config("GET");
      setProducts(data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const deleteProduct = async (id) => {
    // const origin = window.location.origin;
  
    try {
      const { data } = await global.Config("DELETE", id);
      alert(data.msg);
      fetchProducts();
    } catch (error) {
      console.log('Error deleting product:', error);
    }
  };

  const data = {
    labels: products.map(product => product.title),
    datasets: [
      {
        label: 'Price',
        data: products.map(product => product.harga),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      }
    ]
  };

  return (
    <div className="columns mt-5 is-centered">
      <div className="column is-centered ml-6 mr-6">
        <Link to={`add`} className="button is-success mb-3">
          Add New
        </Link>
        <div className="table-container">
          <table className="table is-striped is-fullwidth">
            <thead>
              <tr>
                <th>No</th>
                <th>Jenis Akun</th>
                <th>Title</th>
                <th>Description</th>
                <th>Price</th>
                <th>Stok Terjual</th>
                <th>Name</th>
                <th>Akun</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product, index) => (
                <tr key={product.id}>
                  <td>{index + 1}</td>
                  <td>{product.jenisAkun}</td>
                  <td>{product.title}</td>
                  <td>{product.desc}</td>
                  <td>{product.harga}</td>
                  <td>{product.stokTerjual}</td>
                  <td>{product.name}</td>
                  <td>{product.akun}</td>
                  <td>
                    <div className="buttons are-small">
                      <Link
                        to={`edit/${product.id}`}
                        className="button is-info mr-2"
                      >
                        Edit
                      </Link>
                      <button
                        onClick={() => deleteProduct(product.id)}
                        className="button is-danger"
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="chart-container mt-5 is-centered">
          <Bar data={data} />
        </div>
      </div>
    </div>
  );
};

export default ProductList;

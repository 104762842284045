import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../config/main";
const EditProduct = () => {
  const [accType, setType] = useState("");
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [harga, setHarga] = useState("");
  const [stokTerjual, setStokTerjual] = useState("");
  const [name, setName] = useState("");
  const [akun, setAkun] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
 

  useEffect(() => {
    fetchProductById();
  }, []);

  const fetchProductById = async () => {
    try {
      const { data } = await global.Config("GET",id)
      const { jenisAkun, title, desc, harga, stokTerjual, name, akun } = data;
      setType(jenisAkun);
      setTitle(title);
      setDesc(desc);
      setHarga(harga.toString()); // Pastikan harga di-set sebagai string
      setStokTerjual(stokTerjual.toString()); // Pastikan stokTerjual di-set sebagai string
      setName(name);
      setAkun(akun); // Set nilai akun dari response
    } catch (error) {
      console.error('Error fetching product:', error);
    }
  };

  const updateProduct = async (e) => {
    e.preventDefault();
    // const origin = window.location.origin;

    
// test aja
    try {
      const updateProduct ={
        jenisAkun : accType,
        title,
        desc,
        harga,
        name: name.toLowerCase(),
        akun,
      }
      await global.Config("PATCH",id,updateProduct)
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="columns mt-5 is-centered">
      <div className="column is-half">
        <form onSubmit={updateProduct}>
          <div className="field">
            <label className="label">Jenis Akun</label>
            <div className="control">
              <input
                type="text"
                className="input"
                value={accType}
                onChange={(e) => setType(e.target.value)}
                placeholder="Jenis Akun"
                required
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Title</label>
            <div className="control">
              <input
                type="text"
                className="input"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Title"
                required
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Description</label>
            <div className="control">
              <input
                type="text"
                className="input"
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
                placeholder="Description"
                required
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Price</label>
            <div className="control">
              <input
                type="text"
                className="input"
                value={harga}
                onChange={(e) => setHarga(e.target.value)}
                placeholder="Price"
                required
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Stok Terjual</label>
            <div className="control">
              <input
                type="text"
                className="input"
                value={stokTerjual}
                onChange={(e) => setStokTerjual(e.target.value)}
                placeholder="Stok Terjual"
                required
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Name</label>
            <div className="control">
              <input
                type="text"
                className="input"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
                required
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Akun</label>
            <div className="control">
              <textarea
                className="textarea"
                value={akun} // Menampilkan akun sebagai string
                onChange={(e) => setAkun(e.target.value)} // Set nilai akun sebagai string
                placeholder="Akun"
                required
              />
            </div>
          </div>
          <div className="field">
            <button type="submit" className="button is-success">
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditProduct;
